<template>
  <div>
    <b-modal id="modal-1" title="Form Penilaain" ok-disabled hide-footer no-close-on-esc size="xl">
      <b-alert variant="warning" show>
        <div class="alert-body">
          <b-row>
            <b-col md="6">
              <small class="text-primary">Nama Balai/BPJ <br><strong>{{ rsDetailData.nm_balai }}</strong> </small>
              <h6 class="text-primary"><strong>{{ rsDetailData.kode_sub_kegiatan }}</strong><small
                  style="text-align: justify;">
                  {{ rsDetailData.nama_sub_kegiatan }}
                </small></h6>
              <h6 class="text-primary"> <small><strong>Nama Pekerjaan</strong> </small>
                <br>
                <small style="text-align: justify;">{{ rsDetailData.nama_pekerjaan }} </small>
                <br>
                <small><strong>Rincian Pekerjaan</strong> </small>
                <br>
                <small style="text-align: justify;">{{ rsDetailData.satuan_target_pekerjaan }} </small>

              </h6>

            </b-col>
            <b-col md="6">
              <h6>
                <small> <strong>Jenis Pekerjaan {{ rsDetailData.nm_jenis_pekerjaan }} </strong></small>
              </h6>
              <h6>
                <small class="text-primary">Ruas Jalan </small><br>
                <small class="text-primary"> <strong>{{ rsDetailData.nama_ruas_jalan }} {{
                  rsDetailData.nama_wilayah }} </strong><br>
                  <small>{{ rsDetailData.detail_lokasi }} </small>
                </small>
              </h6>

              <h6 class="text-primary">
                <small class="text-primary">Target Pekerjaan</small><br>
                <strong>{{ rsDetailData.target_pekerjaan }} 20 KM
                </strong>
              </h6>
              <h4 class="text-primary">
                <small>Pagu Usulan</small><br>
                <small><sup>Rp.</sup></small><strong>{{ Rp(rsDetailData.pagu_usulan) }} </strong>
              </h4>
            </b-col>
            <!-- <b-col md="3" class="text-center ">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <h5 class="text-primary"> <strong style="font-size: 20px;"> Skor
                      Penilaian</strong></h5>
                  <h6 class="text-bold text-primary text-center f50">{{ Rp(rsDetailData.bobot_nilai) }}
                  </h6>
                </div>
              </b-alert>
            </b-col> -->
          </b-row>

        </div>
      </b-alert>

      <h5 class="mb-1"><feather-icon icon="GridIcon" size='15' /> Komponen Penilaian Tambahan </h5>

      <b-row class=" ">
        <b-col md="3" class="mb-1">
          <b-form-group label=" Penilaian Top Down" v-if="rsDetailData.manual_penilaian['0'].file_dokumen == ''">
            <b-form-file placeholder="Upload Dokumen Top Down" v-model="file_top_down" ref="file_top_down"
              id="file_top_down" @input="uploadFileTopDown()" size="sm" drop-placeholder="Drop file here..." />
          </b-form-group>



          <h5 class="text-primary mt-1">
            <b-form-checkbox v-model="rsDetailData.manual_penilaian['0'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.manual_penilaian['0'], file_top_down)">
              <span class="text-primary"> Penilaian Top Down Terpenuhi </span>
            </b-form-checkbox>
          </h5>

          <a :href="rsDetailData.manual_penilaian['0'].file_dokumen" target="_blank"
            v-if="rsDetailData.manual_penilaian['0'].file_dokumen != ''">
            <b-button variant="outline-success" block size="sm">
              <feather-icon icon="DownloadIcon" size='15' /> Download Dokumen Top Down
            </b-button>
          </a>
        </b-col>

        <b-col md="3" class="mb-1">
          <b-form-group label=" Penilaian Bottom Up" v-if="rsDetailData.manual_penilaian['1'].file_dokumen == ''">
            <b-form-file placeholder="Upload Dokumen Bottom Up" v-model="file_bottom_up" ref="file_bottom_up"
              id="file_bottom_up" @input="uploadFileBottomUp()" size="sm" drop-placeholder="Drop file here..." />
          </b-form-group>

          <h5 class="text-primary mt-1">
            <b-form-checkbox v-model="rsDetailData.manual_penilaian['1'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.manual_penilaian['1'], file_top_down)">
              <span class="text-primary"> Penilaian Bottom Up Terpenuhi </span>
            </b-form-checkbox>
          </h5>

          <a :href="rsDetailData.manual_penilaian['1'].file_dokumen" target="_blank"
            v-if="rsDetailData.manual_penilaian['1'].file_dokumen != ''">
            <b-button variant="outline-success" block size="sm">
              <feather-icon icon="DownloadIcon" size='15' /> Download Dokumen Bottom Up
            </b-button>
          </a>

        </b-col>


        <b-col md="3" class="mb-1">
          <b-form-group label=" Penilaian Partisipatif" v-if="rsDetailData.manual_penilaian['2'].file_dokumen == ''">
            <b-form-file placeholder="Upload Dokumen Partisipatif" v-model="file_partisipatif" ref="file_partisipatif"
              id="file_partisipatif" @input="uploadFilePartisipatif()" size="sm" drop-placeholder="Drop file here..." />
          </b-form-group>

          <h5 class="text-primary mt-1">
            <b-form-checkbox v-model="rsDetailData.manual_penilaian['2'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.manual_penilaian['2'], file_top_down)">
              <span class="text-primary"> Penilaian Partisipatif Terpenuhi </span>
            </b-form-checkbox>
          </h5>
          <a :href="rsDetailData.manual_penilaian['2'].file_dokumen" target="_blank"
            v-if="rsDetailData.manual_penilaian['3'].file_dokumen != ''">
            <b-button variant="outline-success" block size="sm">
              <feather-icon icon="DownloadIcon" size='15' /> Download Dokumen Partisipatif
            </b-button>
          </a>
        </b-col>


        <b-col md="3" class="mb-1">
          <b-form-group label=" Penilaian Politis" v-if="rsDetailData.manual_penilaian['3'].file_dokumen == ''">
            <b-form-file placeholder="Upload Dokumen Politis" v-model="file_politis" ref="file_politis" id="file_politis"
              @input="uploadFilePolitis()" size="sm" drop-placeholder="Drop file here..." />
          </b-form-group>

          <h5 class="text-primary mt-1">
            <b-form-checkbox v-model="rsDetailData.manual_penilaian['3'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.manual_penilaian['3'], file_top_down)">
              <span class="text-primary"> Penilaian Politis Terpenuhi </span>
            </b-form-checkbox>
          </h5>
          <a :href="rsDetailData.manual_penilaian['3'].file_dokumen" target="_blank"
            v-if="rsDetailData.manual_penilaian['3'].file_dokumen != ''">
            <b-button variant="outline-success" block size="sm">
              <feather-icon icon="DownloadIcon" size='15' /> Download Dokumen Politis
            </b-button>
          </a>
        </b-col>


        <b-col md="3" class="mb-1">
          <b-form-group label=" Penilaian Teknokratis" v-if="rsDetailData.manual_penilaian['4'].file_dokumen == ''">
            <b-form-file placeholder="Upload Dokumen Teknokratis" v-model="file_top_down" ref="file_teknokratis"
              id="file_teknokratis" @input="uploadFileTeknokratis()" size="sm" drop-placeholder="Drop file here..." />
          </b-form-group>

          <h5 class="text-primary mt-1">
            <b-form-checkbox v-model="rsDetailData.manual_penilaian['4'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.manual_penilaian['4'], file_top_down)">
              <span class="text-primary"> Penilaian Teknokratis Terpenuhi </span>
            </b-form-checkbox>
          </h5>
          <a :href="rsDetailData.manual_penilaian['4'].file_dokumen" target="_blank"
            v-if="rsDetailData.manual_penilaian['4'].file_dokumen != ''">
            <b-button variant="outline-success" block size="sm">
              <feather-icon icon="DownloadIcon" size='15' /> Download Dokumen Teknokratis
            </b-button>
          </a>
        </b-col>
      </b-row>
      <hr>
      <h5 class="mb-1"><feather-icon icon="GridIcon" size='15' /> Validasi Dokumen </h5>
      <b-row>


        <b-col md="3" class="mb-1">
          <h6>Dokumen Pendukung Proposal</h6>
          <div v-if="rsDetailData.penilaian_manual_ajuan['0'].file_dokumen != ''">
            <a :href="rsDetailData.penilaian_manual_ajuan['0'].file_dokumen" target="_blank">
              <b-avatar variant="warning" rounded="" size="25">
                <feather-icon icon="DownloadIcon" size='15' /></b-avatar> Dokumen Pendukung Proposal
            </a>
          </div>
          <div v-else>
            <small><i>Tidak ada file unggahan</i></small>
          </div>
          <h5 class="text-primary mt-1 mb-1">
            <b-form-checkbox v-model="rsDetailData.penilaian_manual_ajuan['0'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.penilaian_manual_ajuan['0'])">
              <span class="text-primary"> Dokumen Pendukung Proposal Valid</span>
            </b-form-checkbox>
          </h5>
        </b-col>

        <b-col md="3" class="mb-1">
          <h6>Dokumen Pendukung KAK</h6>
          <div v-if="rsDetailData.penilaian_manual_ajuan['1'].file_dokumen != ''">
            <a :href="rsDetailData.penilaian_manual_ajuan['1'].file_dokumen" target="_blank">
              <b-avatar variant="warning" rounded="" size="25">
                <feather-icon icon="DownloadIcon" size='15' /></b-avatar> Dokumen Pendukung KAK
            </a>
          </div>
          <div v-else>
            <small><i>Tidak ada file unggahan</i></small>
          </div>
          <h5 class="text-primary mt-1 mb-1">
            <b-form-checkbox v-model="rsDetailData.penilaian_manual_ajuan['1'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.penilaian_manual_ajuan['1'])">
              <span class="text-primary"> Dokumen Pendukung KAK Valid</span>
            </b-form-checkbox>
          </h5>
        </b-col>

        <b-col md="3" class="mb-1">
          <h6>Dokumen Pendukung RAB</h6>
          <div v-if="rsDetailData.penilaian_manual_ajuan['2'].file_dokumen != ''">
            <a :href="rsDetailData.penilaian_manual_ajuan['2'].file_dokumen" target="_blank">
              <b-avatar variant="warning" rounded="" size="25">
                <feather-icon icon="DownloadIcon" size='15' /></b-avatar> Dokumen Pendukung RAB
            </a>
          </div>
          <div v-else>
            <small><i>Tidak ada file unggahan</i></small>
          </div>
          <h5 class="text-primary mt-1 mb-1">
            <b-form-checkbox v-model="rsDetailData.penilaian_manual_ajuan['2'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.penilaian_manual_ajuan['2'])">
              <span class="text-primary"> Dokumen Pendukung RAB Valid</span>
            </b-form-checkbox>
          </h5>
        </b-col>

        <b-col md="3" class="mb-1">
          <h6>Dokumen Pendukung Gambar Desain</h6>
          <div v-if="rsDetailData.penilaian_manual_ajuan['3'].file_dokumen != ''">
            <a :href="rsDetailData.penilaian_manual_ajuan['3'].file_dokumen" target="_blank">
              <b-avatar variant="warning" rounded="" size="25">
                <feather-icon icon="DownloadIcon" size='15' /></b-avatar> Dokumen Pendukung Gambar Desain
            </a>
          </div>
          <div v-else>
            <small><i>Tidak ada file unggahan</i></small>
          </div>
          <h5 class="text-primary mt-1 mb-1">
            <b-form-checkbox v-model="rsDetailData.penilaian_manual_ajuan['3'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.penilaian_manual_ajuan['3'])">
              <span class="text-primary"> Dokumen Pendukung Gambar Desain Valid</span>
            </b-form-checkbox>
          </h5>
        </b-col>

        <b-col md="3" class="mb-1">
          <h6>Dokumen Pendukung Lainnya</h6>
          <div v-if="rsDetailData.penilaian_manual_ajuan['4'].file_dokumen != ''">
            <a :href="rsDetailData.penilaian_manual_ajuan['4'].file_dokumen" target="_blank">
              <b-avatar variant="warning" rounded="" size="25">
                <feather-icon icon="DownloadIcon" size='15' /></b-avatar> Dokumen Pendukung Lainnya
            </a>
          </div>
          <div v-else>
            <small><i>Tidak ada file unggahan</i></small>
          </div>
          <h5 class="text-primary mt-1 mb-1">
            <b-form-checkbox v-model="rsDetailData.penilaian_manual_ajuan['4'].status_penilaian" value="1"
              @input="updateStatus(rsDetailData.penilaian_manual_ajuan['4'])">
              <span class="text-primary"> Dokumen Pendukung Gambar Lainnya</span>
            </b-form-checkbox>
          </h5>
        </b-col>

      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BCard, BAvatar, BFormCheckbox, BFormFile, BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal, BFormFile, BFormGroup,
    BAlert, BRow, BCol, BCard, BAvatar, BFormCheckbox
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      item: [],
      selected: {},
      validasi: {},
      input: {

      },
      file_top_down: "",
      file_buttom_up: "",
      file_dokumen: "",
      file_partisipatif: '',
      file_politis: '',
      file_teknokratis: '',
      file: ''
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["updateStatus"],
  methods: {
    updateStatus(val) {
      if (confirm('Apakah anda yakin atas transaksi ini...?')) {
        this.$emit('updateStatus', val, this.file)
      }
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    }
  },
  props: {
    rsDetailData: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style scoped>
.f50 {
  font-size: 50px;
  ;
}
</style>