<template>
  <div>
    <b-card no-body class="card-statistics">
      <b-card-body class="statistics-body" style="margin-bottom: -10px;">
        <b-row>
          <b-col xl="4" sm="6" :class="item.customClass">
            <div @click="statusUsulan('')">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="primary">
                    <feather-icon icon="GridIcon" size='15' />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ rs_data.jm_total }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Total Usulan
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <b-col xl="4" sm="6" :class="item.customClass">
            <div @click="statusUsulan('1')">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="info">
                    <feather-icon icon="AwardIcon" size='15' />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ rs_data.jm_dinilai }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Usulan Sudah Dinilai
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <b-col xl="4" sm="6" :class="item.customClass">
            <div @click="statusUsulan('0')">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="warning">
                    <feather-icon icon="BellIcon" size='15' />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ rs_data.jm_belum }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Usulan Belum Dinilai
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      item: {
        icon: 'TrendingUpIcon',
        color: 'light-primary',
        title: '230k',
        subtitle: 'Sales',
        customClass: 'mb-2 mb-xl-0',
      },
    }
  },
  props: {
    rs_data: {},
    data: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['statusUsulan'],
  methods: {
    statusUsulan(val) {
      this.$emit('statusUsulan', val)
    }
  }
}
</script>
