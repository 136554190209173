<template>
  <div>
    <b-card class="card card-congratulations bg-danger">
      <label class="text-warning">Balai / Bidang </label>
      <b-form-group label="Baial/Bidang">
        <v-select v-model="bid_bal" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
          @input="loadData(bid_bal)" :options="balai_bidang" />
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BAvatar, BCardText, BCard
} from 'bootstrap-vue'
export default {
  components: {
    BTabs, vSelect,
    BTab, BAvatar, BCardText, BCard
  },
  data() {
    return {
      bid_bal: ""
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ['loadData'],
  methods: {
    loadData(val) {
      this.$emit("loadData", val)
    }
  },
  props: {
    balai_bidang: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>