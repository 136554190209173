<template>
  <div>
    <b-row class="match-height">
      <b-col md="4">
        <balai_bidang :balai_bidang=balai_bidang @loadData=loadData></balai_bidang>
      </b-col>
      <b-col md="8">
        <navigasi :rs_data=rs_data @statusUsulan="statusUsulan"> </navigasi>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <tabelpenilaian :rs_data=rs_data.rs :nam_bidang=nam_bidang @detailData=detailData> </tabelpenilaian>
        </b-card>
      </b-col>
    </b-row>
    <penlaianusulan :rsDetailData=rsDetailData @updateStatus=updateStatus></penlaianusulan>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BCard, BRow, BCol
} from 'bootstrap-vue'
import Navigasi from './component/navigasi.vue'
import balai_bidang from './component/balai_bidang.vue'
import tabelpenilaian from './component/tabel_penilaian.vue'
import penlaianusulan from './component/penlaianUsulan.vue'
import { alpha } from 'vee-validate/dist/rules'
export default {
  components: {
    BTabs,
    BTab, BCard, BRow, BCol,
    balai_bidang,
    Navigasi,
    tabelpenilaian, penlaianusulan
  },
  data() {
    return {
      rs_data: [],
      balai_bidang: [],
      nam_bidang: {},
      id_balai_bidang: '',
      rsDetailData: {},
      statususulan: ''
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.get_balai_bidang();
  },
  methods: {
    statusUsulan(val) {
      this.statususulan = val
      this.load_data();
    },
    async updateStatus(val, file) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/penilaian/act_penilaian',
        data: {
          id_usulan_kegiatan: val.id_usulan_kegiatan,
          id_bobot_penilaian: val.id_bobot_penilaian,
          file: file
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;
          self.notification('warning', "", response.data.message);
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });

    },
    detailData(val) {
      this.rsDetailData = val
    },
    loadData(val) {
      this.nam_bidang = val
      this.id_balai_bidang = val.id_bidang;
      this.load_data();
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/penilaian/load_data',
        data: {
          id_balai_bidang: self.id_balai_bidang,
          statususulan: self.statususulan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_balai_bidang() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/penilaian/balai_bidang',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.balai_bidang = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>